.collection-teaser-large {
	color: currentColor;
	text-decoration: none;
}

.collection-teaser-large__top {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: flex;
		justify-content: space-between;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
		margin-inline-end: var(--space-120--px); /* 1 column + 1 gutter */
	}
}

.collection-teaser-large__content {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + ((4 - 1) * var(--gutter)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 5)) + ((5 - 1) * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: calc(((100% - (7 * var(--gutter))) / (8 / 3)) + ((3 - 1) * var(--gutter)));
	}
}

.collection-teaser-large__content__title {
	margin-block-end: 0;
}

.collection-teaser-large__content__cta {
	display: block;
	text-decoration: underline;
	transition: color 128ms ease-in-out;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block-start: var(--space-5--rem);
	}

	&:is(.collection-teaser-large:hover *) {
		color: var(--color-brown);
	}

	&:is(.collection-teaser-large:active *) {
		color: var(--color-foreground);
	}
}

.collection-teaser-large__content__excerpt {
	margin-block-start: var(--space-30--rem);
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	display: -webkit-box;
	overflow: hidden;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-start: 0;
		width: calc(((100% - (11 * var(--gutter))) / (12 / 8)) + ((8 - 1) * var(--gutter)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 7)) + ((7 - 1) * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: calc(((100% - (7 * var(--gutter))) / (8 / 5)) + ((5 - 1) * var(--gutter)));
	}
}

.collection-teaser-large__image {
	margin-block-start: var(--space-30--rem);
	transition: opacity 128ms ease-in-out;
	width: 100%;
	height: 100%;

	&:is(.collection-teaser-large:hover *) {
		opacity: 0.85;
	}

	&:is(.collection-teaser-large:active *) {
		opacity: 1;
	}
}
