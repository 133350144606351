.wysiwyg {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 5)) + (5 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}
