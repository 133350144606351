.hotspots {
	position: relative;
	width: 100%;
}

.hotspot {
	position: absolute;

	&:hover {
		z-index: 2;
	}
}

.hotspot__area {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3.125rem;
	height: 3.125rem;
}

/* #region Circle */
.hotspot__circle {
	background-color: var(--color-black-25);
	border: 2px solid transparent;
	border-radius: 50%;
	pointer-events: none;
	position: relative;
	transition:
		border-color 128ms ease-in-out,
		background-color 128ms ease-in-out;
	width: 1.875rem;
	height: 1.875rem;
}

.hotspot__circle {
	&:is(.hotspot:hover *) {
		border: 2px solid var(--color-grey);
	}
}

.hotspot__circle__inner {
	background-color: var(--color-white-85);
	border-radius: var(--border-radius-30);
	box-shadow: 0 0 3px 0 var(--color-black-50);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(1);
	transition:
		background-color 128ms ease-in-out,
		transform 128ms ease-in-out;
	width: 0.625rem;
	height: 0.625rem;

	&:is(.hotspot:hover *) {
		background-color: var(--color-white);
		transform: translate(-50%, -50%) scale(0.8);
	}
}

/* #endregion Circle */

/* #region Content */
.hotspot__link {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

[data-hotspot-details] {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	transition: opacity 128ms ease-in;
	z-index: 3;
}

.hotspot__area:not([data-anchor-position]) [data-hotspot-details] {
	display: none;
}

.hotspot__area[data-anchor-position~="top"] [data-hotspot-details] {
	top: 3.125rem;
	bottom: initial;
}

.hotspot__area[data-anchor-position~="right"] [data-hotspot-details] {
	left: 0;
	transform: translateX(-100%);
}

.hotspot__area[data-anchor-position~="bottom"] [data-hotspot-details] {
	top: initial;
	bottom: 3.125rem;
}

.hotspot__area[data-anchor-position~="left"] [data-hotspot-details] {
	right: initial;
	left: 3.125rem;
}

.hotspot > [data-state="open"] [data-hotspot-details] {
	opacity: initial;
	pointer-events: initial;
	z-index: 4;
}

.hotspot__link:is(:focus, :focus-visible) [data-hotspot-details] {
	opacity: initial;
	pointer-events: initial;
	z-index: 5;
}

/* #region extra hover area when open */
.hotspot > [data-state="open"]:is([data-anchor-position~="right"], [data-anchor-position~="left"])::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: rotate(45deg);
	width: 100%;
	height: 100%;
	z-index: 0;
}

.hotspot > [data-state="open"][data-anchor-position~="top"]::before {
	top: 50%;
}

.hotspot > [data-state="open"][data-anchor-position~="right"]::before {
	right: 50%;
	left: initial;
}

.hotspot > [data-state="open"][data-anchor-position~="bottom"]::before {
	top: initial;
	bottom: 50%;
}

.hotspot > [data-state="open"][data-anchor-position~="left"]::before {
	right: initial;
	left: 50%;
}
/* #endregion extra hover area when open */
/* #endregion Content */
