.image-duo {
	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		display: flex;
		justify-content: space-between;
	}
}

.image-duo__item:first-child {
	margin-block-end: var(--space-50--rem);

	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		margin-block-end: 0;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.image-duo__item {
		width: calc(50% - calc(var(--gutter) / 2));
	}
}

.image-duo__item__image {
	margin: 0;
	width: 100%;
}

.image-duo__item__image__source {
	width: 100%;
}

.image-duo__item__caption {
	display: flex;
	margin-block-start: var(--space-10--rem);
	align-items: baseline;
}

.image-duo__item__caption .icon {
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-10--px);
	width: 0.6875rem;
	height: 0.6875rem;
}
