.image-with-text {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.image-with-text[data-image-position="right"] {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		flex-direction: row-reverse;
	}
}

.image-with-text__image {
	margin: 0;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(50% - calc(var(--gutter) / 2));
	}
}

.image-with-text__image__caption {
	display: flex;
	margin-block-start: var(--space-10--rem);
	align-items: baseline;
}

.image-with-text__image__caption .icon {
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-10--px);
	width: 0.6875rem;
	height: 0.6875rem;
}

.image-with-text__text-wrapper {
	margin-block-start: var(--space-30--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-start: 0;
		width: calc(50% - calc(var(--gutter) / 2));
	}
}

.image-with-text__text {
	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin: 0 auto;
		width: calc(((100% - (5 * var(--gutter))) / (6 / 4)) + ((4 - 1) * var(--gutter)));
	}
}
