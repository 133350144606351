.tooltip {
	background-color: var(--color-white-85);
	box-sizing: border-box;
	color: var(--color-foreground);
	cursor: pointer;
	display: block;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-15--rem);
	position: relative;
	text-decoration: none;
	width: 11.875rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 14.3125rem;
	}
}

.tooltip:hover .tooltip__hero__title {
	color: var(--color-brown);
	text-decoration: underline;
}

.tooltip:active .tooltip__hero__title {
	color: var(--color-foreground);
	text-decoration: underline;
}

.tooltip__hero {
	display: flex;
	margin-block-end: var(--space-10--rem);
	justify-content: space-between;
}

.tooltip__hero__title {
	margin: 0;
}

.tooltip__data {
	position: relative;
	min-height: calc(4.5625rem + 2.625rem + var(--space-10--rem));
}

@supports selector(:has(*)) {
	.tooltip__data {
		min-height: auto;
	}

	.tooltip__data:has(.tooltip__image) {
		min-height: 4.5625rem;
	}

	.tooltip__data:has(.tooltip__content-a):has(.tooltip__image) {
		min-height: calc(4.5625rem + 2.625rem + var(--space-10--rem));

		/* from-small,tablet-p,min-width-768px */
		@media (width >= 48rem) {
			min-height: calc(4.5625rem + 2.8125rem + var(--space-10--rem));
		}
	}
}

.tooltip__content,
.tooltip__content__description,
.tooltip__content__title {
	margin: 0;
}

.tooltip__content > div:not(:last-child) {
	margin-block-end: var(--space-10--rem);
}

.tooltip__content > div:not(.tooltip__content-a) {
	width: calc(100% - 4.5625rem - var(--space-10--px));
}

.tooltip__content__title {
	font-weight: 700;
}

.tooltip__image {
	margin: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 4.5625rem;
	height: 4.5625rem;
}
