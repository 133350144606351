.collection-teaser-suggestions {
	border-top: 1px solid var(--color-grey);
}

.collection-teaser-suggestions__title {
	margin-block-end: var(--space-50--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}
}

/* #region Only show 2 list items */
/* up-to-medium,max-width-1023px */
@media (width < 64rem) {
	.collection-teaser-suggestions .collection-teaser-list__item:nth-child(n + 3) {
		display: none;
	}
}
/* #endregion Only show 2 list items */
