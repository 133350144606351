.hero-default {
	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}

/* #region Title */
.hero-default__title {
	text-wrap: balance;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-start: calc(((100% - (10 * var(--gutter))) / (11 / 3)) + (3 * var(--gutter)));
	}
}

/* #endregion Title */

/* #region Intro */
.hero-default__intro {
	margin-block-start: var(--space-50--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
		margin-block-start: var(--space-80--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 5)) + (5 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-start: calc(((100% - (10 * var(--gutter))) / (11 / 5)) + (5 * var(--gutter)));
		margin-block-start: var(--space-100--rem);
	}
}

/* #endregion Intro */
