.navigation-overlay {
	background-color: var(--color-background);
	box-sizing: border-box;
	padding-block-start: var(--space-15--rem);
	padding-block-end: var(--space-50--rem);
	min-height: 100%;
}

.navigation-overlay__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* #region Logo */
.navigation-overlay__logo {
	display: block;
	width: 11.5625rem;
	height: 0.6875rem;

	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		width: 12.625rem;
		height: 0.75rem;
	}
}

.navigation-overlay__logo .icon {
	display: block;
	fill: var(--color-foreground);
	width: 100%;
	height: 100%;
}
/* #endregion Logo */

/* #region Close Button */
.navigation-overlay__close-button {
	-mrh-resets: button; /* button-reset */
	display: block;
	width: 0.875rem;
	height: 0.875rem;
}

.navigation-overlay__close-button .icon {
	display: block;
	fill: var(--color-foreground);
	width: 100%;
	height: 100%;
}
/* #endregion Close Button */

.navigation-overlay__main,
.navigation-overlay__footer {
	margin-block-start: var(--space-50--rem);
}

.navigation-overlay__menu:not(:last-child) {
	margin-block-end: var(--space-30--rem);
}

.navigation-overlay ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* #region Main List */
.navigation-overlay__main__list__link {
	border-bottom: 1px solid var(--color-grey);
	color: var(--color-grey-dark);
	display: block;
	padding-block: var(--space-10--rem);
	text-decoration: none;
	transition:
		color 96ms ease-in-out,
		border-bottom-color 96ms ease-in-out;
}

.navigation-overlay__main__list__link:hover,
.navigation-overlay__main__list__item[data-is-current] .navigation-overlay__main__list__link {
	border-bottom-color: var(--color-brown);
	color: var(--color-brown);
}

.navigation-overlay__main__list__link:active {
	border-bottom-color: var(--color-foreground);
	color: var(--color-foreground);
}
/* #endregion Main List */

/* #region Footer list */
.navigation-overlay__footer__list__item:not(:last-child) {
	margin-block-end: var(--space-5--rem);
}

.navigation-overlay__footer__list__link {
	color: var(--color-grey-dark);
	text-decoration: none;
	transition: color 96ms ease-in-out;
}

.navigation-overlay__footer__list__link:hover,
.navigation-overlay__footer__list__item[data-is-current] .navigation-overlay__footer__list__link {
	color: var(--color-brown);
	text-decoration: underline;
}

.navigation-overlay__footer__list__link:active {
	color: var(--color-foreground);
}
/* #endregion Footer list */
