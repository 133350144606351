.video {
	margin: 0;
}

/* #region Main */
.video__main {
	aspect-ratio: 16 / 9;
	background-color: var(--color-foreground);
	position: relative;
}

.video__main__player__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.video__main__controls {
	display: flex;
	padding-inline: var(--space-15--px);
	padding-block-start: var(--space-30--rem);
	padding-block-end: var(--space-15--rem);
	justify-content: space-between;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: var(--space-20--px);
		padding-block-start: var(--space-40--rem);
		padding-block-end: var(--space-20--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.video__main__controls::before {
	background: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.01) 8.1%, rgb(0 0 0 / 0.039) 15.5%, rgb(0 0 0 / 0.083) 22.5%, rgb(0 0 0 / 0.14) 29%, rgb(0 0 0 / 0.207) 35.3%, rgb(0 0 0 / 0.282) 41.2%, rgb(0 0 0 / 0.36) 47.1%, rgb(0 0 0 / 0.44) 52.9%, rgb(0 0 0 / 0.518) 58.8%, rgb(0 0 0 / 0.593) 64.7%, rgb(0 0 0 / 0.66) 71%, rgb(0 0 0 / 0.717) 77.5%, rgb(0 0 0 / 0.761) 84.5%, rgb(0 0 0 / 0.79) 91.9%, rgb(0 0 0 / 0.8) 100%);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.video__main__toggle {
	display: block;
	margin-left: auto;
}

.video__main__toggle__button {
	-mrh-resets: button;
	background-color: transparent;
	border: 1px solid var(--color-white);
	border-radius: 9999px;
	color: var(--color-white);
	display: flex;
	justify-content: center;
	align-items: center;
	transition:
		color 96ms linear,
		background-color 96ms linear;
	width: 1.875rem;
	height: 1.875rem;
}

.video__main__toggle__button:hover {
	background-color: var(--color-background);
	color: var(--color-brown);
}

.video__main__toggle__button:active {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

.video__main__toggle__button span {
	background-color: currentColor;
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 0.625rem;
	height: 0.625rem;
}

.video__main__toggle__button__pause {
	display: none;
	mask-image: url("../../images/pause.svg");
}

.video__main__toggle[data-playing] .video__main__toggle__button__pause {
	display: block;
}

.video__main__toggle__button__play {
	display: block;
	margin-left: 0.125rem;
	mask-image: url("../../images/play.svg");
}

.video__main__toggle[data-playing] .video__main__toggle__button__play {
	display: none;
}

/* #endregion Main */

/* #region Caption */
.video__caption {
	display: flex;
	margin-block-start: var(--space-10--rem);
	align-items: baseline;
}

.video__caption .icon {
	display: block;
	flex-shrink: 0;
	margin-inline-end: 8px;
	width: 0.6875rem;
	height: 0.6875rem;
}
/* #endregion Caption */
