.asset-links {
	background-color: var(--color-white);
	padding-block: var(--space-50--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.asset-links {
		padding-block: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.asset-links {
		padding-block: var(--space-100--rem);
	}
}

.asset-links__inner {
	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + ((4 - 1) * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}

.asset-links__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.asset-links__list__item span {
	pointer-events: none;
}

.asset-links__list__item :any-link {
	border-bottom: 1px solid var(--color-grey);
	color: var(--color-foreground);
	display: flex;
	padding-block: var(--space-20--rem);
	align-items: baseline;
	text-decoration: none;
	transition:
		color 128ms ease-in-out,
		border 128ms ease-in-out;
}

.asset-links__list__item :any-link::before {
	background: currentColor;
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-20--px);
	mask-image: url("../../images/download.svg");
	mask-position: center;
	pointer-events: none;
	width: 1rem;
	height: 1rem;
}

.asset-links__list__item :any-link:hover {
	border-color: var(--color-brown);
	color: var(--color-brown);
}

.asset-links__list__item :any-link:active {
	border-color: var(--color-foreground);
	color: var(--color-foreground);
}
