.wrapper {
	box-sizing: border-box;
	padding-inline: var(--space-15--px);
	width: 100%;

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin: 0 auto;
		padding: 0;
		max-width: 87.5rem;
	}
}

/* #region Narrow */
.wrapper[data-size="narrow"] {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: var(--space-80--px);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-inline: var(--space-100--px);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding: 0;
		max-width: 72.5rem;
	}
}

/* #endregion Narrow */
