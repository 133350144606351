.video-overlay {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: calc(100% - 148px);
	height: auto;
	z-index: 2;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(100% - (2 * var(--space-80--px)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		width: calc(100% - (2 * var(--space-100--px)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: 69.625rem;
	}
}

/* #region Media */
.video-overlay__media {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.video-overlay__media__player {
	aspect-ratio: 16 / 9;
	background-color: var(--color-foreground);
	display: block;
	margin: 0 auto;
	position: relative;
	width: 100%;
	max-height: calc(100vh - (var(--space-15--rem) * 2));
}

.video-overlay__media__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
/* #endregion Media */

.video-overlay__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

/* #region Button */
.video-overlay__close-button-wrapper {
	display: block;
	position: fixed;
	top: var(--space-15--rem);
	right: var(--space-15--px);

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		top: var(--space-20--rem);
		right: var(--space-20--px);
	}
}

.video-overlay__close-button {
	-mrh-resets: button;
	display: block;
	padding-inline: var(--space-15--rem);
	padding-block: var(--space-15--rem);
}

.video-overlay__close-button .icon {
	display: block;
	fill: currentColor;
	width: 1rem;
	height: 1rem;
}
/* #endregion Button */
