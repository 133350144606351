.accordion-anchors {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + ((4 - 1) * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}

.accordion-anchors__item:not(:last-child) {
	margin-block-end: var(--space-10--rem);
}

.accordion-anchors__item__link,
.accordion-anchors__item__link:active {
	color: var(--color-foreground);
}

.accordion-anchors__item__link {
	transition: color 128ms linear;
}

.accordion-anchors__item__link:hover {
	color: var(--color-brown);
}
