.site-footer {
}

.site-footer__title {
	margin-block-end: 0;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}

/* #region Text */
.site-footer__text {
	margin-block-start: var(--space-50--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
		margin-block-start: var(--space-80--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 5)) + (5 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
		margin-block-start: var(--space-100--rem);
	}
}

/* #endregion Text */

/* #region Ruler */
.site-footer__ruler {
	margin-block: var(--space-50--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-100--rem);
	}
}

/* #endregion Ruler */

.site-footer__bottom {
	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block-end: var(--space-50--rem);
	}
}

/* #region Navigation */
.site-footer__navigation {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.site-footer__navigation__list {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__navigation__list__item {
	margin-block-end: var(--space-10--rem);
}

.site-footer__navigation__list__item:not(:last-child) {
	margin-inline-end: var(--space-10--px);
}

.site-footer__navigation__list__item__link {
	color: var(--color-foreground);
	display: inline-block;
	text-decoration: none;
	transition: color 128ms ease-in-out;
}

.site-footer__navigation__list__item__link:hover,
.site-footer__navigation__list__item[data-is-current] .site-footer__navigation__list__item__link {
	color: var(--color-brown);
	text-decoration: underline;
}

.site-footer__navigation__list__item__link:active {
	color: var(--color-foreground);
	text-decoration: underline;
}
/* #endregion Navigation */

.site-footer__fit-logo {
	margin-block-start: var(--space-20--rem);
}
