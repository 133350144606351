.blockquote {
	margin-block: 0;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.blockquote {
		margin-inline-end: var(--space-120--px);
	}
}

.blockquote__text {
	text-wrap: balance;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.blockquote__text {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.blockquote__text {
		margin-inline-start: calc(((100% - (10 * var(--gutter))) / (11 / 3)) + (3 * var(--gutter)));
	}
}
