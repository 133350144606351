:root {
	/* #region Colors */
	--color-foreground: rgb(43 12 12);
	--color-background: rgb(250 249 248);
	--color-black-25: rgb(43 12 12 / 0.25);
	--color-black-50: rgb(43 12 12 / 0.5);
	--color-brown: rgb(90 77 61);
	--color-error: rgb(219 79 52);
	--color-grey-dark: rgb(102 102 102);
	--color-grey: rgb(220 220 220);
	--color-white-85: rgb(255 255 255 / 0.85);
	--color-white: rgb(255 255 255);
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "Helvetica Neue", "Helvetica Neue Fallback", sans-serif;
	--font-serif: "Victor", "Victor Fallback", "Georgia", serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-2--px: 2px;
	--space-2--rem: 0.125rem;
	--space-5--px: 5px;
	--space-5--rem: 0.3125rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-50--px: 50px;
	--space-50--rem: 3.125rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	--space-100--px: 100px;
	--space-100--rem: 6.25rem;
	--space-120--px: 120px;
	--space-120--rem: 7.5rem;
	--space-160--px: 160px;
	--space-160--rem: 10rem;
	--space-360--px: 360px;
	--space-360--rem: 22.5rem;
	--space-600--px: 600px;
	--space-600--rem: 37.5rem;
	/* #endregion Spacers */

	/* #region Border-Radius */
	--border-radius-30: 30px;
	/* #endregion Border-Radius */

	/* #region Gutter */
	--gutter: 10px;
	/* #endregion Gutter */

	/* #region Z-Index */
	--z-index-modal-dialog: 100;
	/* #endregion Z-Index */
}

/* min-width-375px */
@media (width >= 23.4375rem) {
	:root {
		--gutter: 15px;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	:root {
		--gutter: 14px;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	:root {
		--gutter: 18px;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	:root {
		--gutter: 26px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	:root {
		--gutter: 40px;
	}
}
