.image {
	margin: 0;
	width: 100%;
}

.image__caption {
	display: flex;
	margin-block-start: var(--space-10--rem);
	align-items: baseline;
}

.image__caption .icon {
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-10--px);
	width: 0.6875rem;
	height: 0.6875rem;
}
