.collection-teaser-small {
	color: currentColor;
	text-decoration: none;
}

.collection-teaser-small__cta {
	display: block;
	text-decoration: underline;
	transition: color 128ms ease-in-out;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block-start: var(--space-5--rem);
	}

	&:is(.collection-teaser-small:hover *) {
		color: var(--color-brown);
	}

	&:is(.collection-teaser-small:active *) {
		color: var(--color-foreground);
	}
}

.collection-teaser-small__image {
	padding-block-start: var(--space-30--rem);
	transition: opacity 128ms ease-in-out;
	width: 100%;

	&:is(.collection-teaser-small:hover *) {
		opacity: 0.85;
	}

	&:is(.collection-teaser-small:active *) {
		opacity: 1;
	}
}
