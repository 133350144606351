.accordions {
	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + ((4 - 1) * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}

.accordions__title {
	margin-block-end: var(--space-30--rem);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-50--rem);
	}
}

.accordions__title:not(:first-child) {
	margin-block-start: var(--space-50--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-start: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-start: var(--space-100--rem);
	}
}

/* #region Accordion */
.accordion {
	box-sizing: border-box;
	display: block;
}

.accordion__trigger {
	-mrh-resets: button;
	border-bottom: 1px solid var(--color-grey);
	display: flex;
	padding-block: var(--space-20--rem);
	justify-content: space-between;
	align-items: baseline;
	transition:
		color 96ms linear,
		border-bottom 96ms linear;
	width: 100%;
}

.accordion__trigger:hover,
.accordion__trigger[aria-expanded="true"] {
	border-bottom-color: var(--color-brown);
	color: var(--color-brown);
}

.accordion__trigger:active {
	border-bottom-color: var(--color-foreground);
	color: var(--color-foreground);
}

.accordion__trigger__title {
	margin-inline-end: var(--space-10--px);
	pointer-events: none;
	text-align: left;
}

/* #region Handle */
.accordion__trigger__handle {
	flex-shrink: 0;
	pointer-events: none;
	text-decoration: underline;
	transform: scale(1);
	transition: transform 128ms linear;
	width: 0.6875rem;
	height: 0.6875rem;
}

.accordion__trigger__handle .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.accordion__trigger[aria-expanded="false"] .accordion__trigger__handle {
	transform: scale(-1);
}
/* #endregion Handle */

/* #region Panel */
.accordion__panel {
	border-bottom: 1px solid var(--color-brown);
	padding-block: var(--space-30--rem);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block: var(--space-50--rem);
	}
}

.accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.accordion__panel[hidden="until-found"] {
		border-bottom: none;
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion__panel__inner {
	display: grid;
	grid-row-gap: var(--space-30--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-column-gap: 1.125rem;
		grid-template-columns: repeat(2, 1fr);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-row-gap: var(--space-100--rem);
		grid-column-gap: var(--space-40--px);
	}
}

.accordion__panel__inner__item {
}
/* #endregion Panel */

/* #region No-JS */
.accordion__no-js {
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
}
/* #endregion No-JS */
/* #endregion Accordion */
