.collection-teaser-list {
	display: grid;
	grid-row-gap: var(--space-50--rem);
	grid-column-gap: var(--gutter);
	list-style: none;
	margin: 0;
	padding: 0;

	/* from 375px */
	@media (width >= 23.4375rem) {
		grid-template-columns: repeat(2, 1fr);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-row-gap: var(--space-80--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-template-columns: repeat(3, 1fr);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-row-gap: var(--space-100--rem);
	}
}
