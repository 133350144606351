* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		font-size: 1.125rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		font-size: 1.25rem;
	}
}

h1,
blockquote {
	margin-block: var(--space-50--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-100--rem);
	}
}

h2,
h3,
p,
ol,
ul {
	margin-block: var(--space-30--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-40--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-50--rem);
	}
}

h4,
h5,
h6 {
	margin-block-end: var(--space-5--rem);
}

h4 + p,
h5 + p,
h6 + p {
	margin-block-start: var(--space-5--rem);
}

:is(h1, h2, h3, h4, h5, h6, p):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

.type-a,
h1 {
	font-family: var(--font-serif);
	font-size: 2.5rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.2; /* 120% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-a,
	h1 {
		font-size: 3.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-a,
	h1 {
		font-size: 3.75rem;
	}
}

.type-b,
h2,
blockquote p {
	font-family: var(--font-serif);
	font-size: 1.875rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.4; /* 140% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-b,
	h2,
	blockquote p {
		font-size: 2.1875rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-b,
	h2,
	blockquote p {
		font-size: 2.5rem;
	}
}

.type-c,
h3 {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5; /* 150% */
	text-transform: uppercase;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-c,
	h3 {
		font-size: 1.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-c,
	h3 {
		font-size: 1.1875rem;
	}
}

.type-d {
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.5; /* 150% */

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		font-size: 1.375rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		font-size: 1.5rem;
	}
}

.type-e,
p {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-e,
	p {
		font-size: 1.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-e,
	p {
		font-size: 1.25rem;
	}
}

.type-f,
table caption {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-f,
	table caption {
		font-size: 0.9375rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-f,
	table caption {
		font-size: 1rem;
	}
}

.type-g {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		font-size: 2.5rem;
	}
}

.type-h {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		font-size: 1.25rem;
	}
}

.type-i,
h4 {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5; /* 150% */
	text-transform: uppercase;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-i,
	h4 {
		font-size: 1rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-i,
	h4 {
		font-size: 1.0625rem;
	}
}

h5,
h6 {
	font-size: 0.875rem;
	line-height: normal;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		font-size: 1rem;
	}

	/* from-wide,desktop,min-width-1280px */
	@media (width >= 80rem) {
		font-size: 1.25rem;
	}
}

i,
em,
blockquote {
	font-style: italic;
}
