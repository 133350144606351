.pagination {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;
	align-items: center;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.pagination {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}
}

.pagination__item {
	box-sizing: border-box;
	display: flex;
	list-style-type: none;
	justify-content: center;
	align-items: center;
	width: calc((48px + 48px + 3rem) / 3);
	height: calc((44px + 44px + 2.75rem) / 3);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		height: calc((47px + 47px + 2.9375rem) / 3);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		height: calc((50px + 50px + 3.125rem) / 3);
	}
}

.pagination__item .icon {
	transform: translateX(0.0625rem);
	width: 0.5625rem;
	height: 0.9375rem;
}

.pagination__item[data-position="left"] .icon {
	transform: rotate(-180deg) translateX(0.0625rem);
}

.pagination__item[data-is-current] {
	background-color: var(--color-foreground);
	color: var(--color-white);
}

/* #region Link */
.pagination__item__link {
	box-sizing: border-box;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition:
		border-radius 128ms ease-in-out,
		border-color 128ms ease-in-out,
		background-color 128ms ease-in-out,
		color 128ms ease-in-out;
	width: 100%;
	height: 100%;
}

.pagination__item .pagination__item__link {
	border: 2px solid transparent;
	color: var(--color-foreground);
	text-decoration: none;
}

.pagination__item:hover .pagination__item__link {
	border: 2px solid var(--color-brown);
	color: var(--color-brown);
}

.pagination__item:active .pagination__item__link {
	border: 2px solid var(--color-foreground);
	color: var(--color-foreground);
}
/* #endregion Link */
