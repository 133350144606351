.secondary-button {
	-mrh-resets: button; /* button-reset */
	border: 1px solid var(--color-background);
	border-radius: var(--border-radius-30);
	color: var(--color-background);
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */
	padding-inline: var(--space-15--px);
	padding-block: var(--space-5--rem);
	transition:
		color 96ms linear,
		background-color 96ms linear;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		font-size: 0.9375rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		font-size: 1rem;
	}
}

.secondary-button:hover {
	background-color: var(--color-background);
	color: var(--color-brown);
}

.secondary-button:active {
	background-color: var(--color-background);
	color: var(--color-foreground);
}
