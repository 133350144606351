.l-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	min-height: 100vh;
	min-height: 100svh;
}

.l-error__container {
	padding-inline: var(--space-15--px);
	max-width: 25rem;
}

.l-error__header h1 {
	margin-block-start: var(--space-40--rem);
}

.l-error__header__logo svg {
	display: block;
	fill: var(--color-foreground);
	width: 13.6875rem;
}

.l-error__main {
	margin-block-start: var(--space-40--rem);
}
