.row {
	margin-block: var(--space-50--rem);
	position: relative;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-100--rem);
	}
}
