.site-header {
	display: flex;
	padding-block-start: var(--space-15--rem);
	justify-content: space-between;
	align-items: center;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-block-start: var(--space-20--rem);
	}
}

.site-header__desktop {
	display: none;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		display: flex;
		justify-content: space-between;
		width: calc(((100% - (11 * var(--gutter))) / (12 / 9)) + ((9 - 1) * var(--gutter)));
	}
}

.site-header__mobile {
	display: flex;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		display: none;
	}
}

/* #region Logo */
.site-header__logo {
	display: block;
	width: 11.5625rem;
	height: 0.6875rem;

	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		width: 12.625rem;
		height: 0.75rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 13.75rem;
		height: 0.8125rem;
	}
}

.site-header__logo .icon {
	display: block;
	fill: var(--color-foreground);
	width: 100%;
	height: 100%;
}
/* #endregion Logo */

/* #region Hamburger */
.site-header__hamburger {
	-mrh-resets: button; /* button-reset */
	display: block;
	width: 0.875rem;
	height: 0.875rem;
}

.site-header__hamburger__line {
	background-color: var(--color-foreground);
	display: block;
	width: 0.875rem;
	height: 0.125rem;
}

.site-header__hamburger__line:first-child {
	margin-bottom: 0.25rem;
}
/* #endregion Hamburger */

.site-header__navigation__list {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-header__list__item:not(:last-child) {
	margin-inline-end: var(--space-10--px);
}

.site-header__list__item__link {
	color: var(--color-foreground);
	display: inline-block;
	text-decoration: none;
	transition: color 128ms ease-in-out;
}

.site-header__list__item__link:hover,
.site-header__list__item[data-is-current] .site-header__list__item__link {
	color: var(--color-brown);
	text-decoration: underline;
}

.site-header__list__item__link:active {
	color: var(--color-foreground);
	text-decoration: underline;
}
