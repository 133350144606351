.articles-list {
	display: grid;
	grid-row-gap: var(--space-50--rem);

	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		grid-column-gap: var(--gutter);
		grid-template-columns: repeat(2, 1fr);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-row-gap: var(--space-80--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-template-columns: repeat(3, 1fr);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-row-gap: var(--space-100--rem);
	}
}

.articles-list__item {
	color: var(--color-foreground);
	display: flex;
	flex-direction: column;
	text-decoration: none;
}

.articles-list__item__title {
	margin-block-end: var(--space-5--rem);
	transition: color 128ms ease-in-out;
}

.articles-list__item:hover .articles-list__item__title,
.articles-list__item:focus .articles-list__item__title {
	color: var(--color-brown);
	text-decoration: underline;
}

.articles-list__item__taxonomies {
	margin: 0;
}

.article-list__item__image {
	margin-block-start: auto;
	transition: opacity 128ms ease-in-out;

	&::before {
		content: "";
		display: block;
		margin-block-start: var(--space-30--rem);
	}
}

.articles-list__item:hover .article-list__item__image,
.articles-list__item:focus .article-list__item__image {
	opacity: 0.85;
}

.article-list__item__image__source {
	margin: 0;
	width: 100%;
}
