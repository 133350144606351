.hero-detail {
}

.hero-detail__intro {
	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}

.hero-detail__title {
	margin-block-end: var(--space-30--rem);
}

.hero-detail__quote {
	margin-block-start: var(--space-50--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 9)) + ((9 - 1) * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 8)) + ((8 - 1) * var(--gutter)));
	}
}
